import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const LastSection = ({ handleStart }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box>
        <CheckCircleOutlineRoundedIcon
          sx={{
            color: 'green',
            width: '100px',
            height: '100px',
            mb: '50px',
          }}
        />
      </Box>

      <Typography
        sx={{
          mb: '50px',
          fontSize: '28px',
        }}
      >
        Tebrikler. Başarılı bir şekilde tamamladınız!
      </Typography>

      <LoadingButton
        onClick={handleStart}
        variant='contained'
        sx={{
          width: { xs: '100%', sm: '160px' },
        }}
      >
        Başla
      </LoadingButton>
    </Box>
  );
};

export default LastSection;
