const makeActive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsActive($activityId: String!) {
      moderator_setActivityAsActive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const makePassive = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_setActivityAsPassive($activityId: String!) {
      moderator_setActivityAsPassive(activityId: $activityId) {
        success
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const createActivity = ({ values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_createActivity($activityInput: ActivityByModeratorInput!) {
      moderator_createActivity(activityInput: $activityInput) {
        _id
      }
    }
              `,
    variables: {
      "activityInput": {
        "title": values?.title,
        "additionalDetails": values?.additionalDetails,
        "duration": values?.duration,
        "fee": values?.fee,
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires,
        "isReservationRequired": values?.isReservationRequired,
        "listingId": values?.listingId,
        "locationId": values?.locationId,
        "price": Number(values?.price),
        "quota": values?.quota ?? "",
        "schedule": {
          "days": values?.schedule?.days,
          // "endDate": values?.schedule?.endDate,
          // "startDate": values?.schedule?.startDate,
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType,
          "totalDayCount": values?.schedule?.totalDayCount
        }
      }
    }

  })

  return data

}


const removeActivity = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_removeActivity($activityId: String!) {
      moderator_removeActivity(activityId: $activityId) {
        success
      }
    }
    `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}


const updateActivity = ({ activityId, values }) => {

  const data = JSON.stringify({
    query: `
    mutation Moderator_updateActivity($activityId: String!, $updateInput: UpdateActivityByModeratorInput!) {
      moderator_updateActivity(activityId: $activityId, updateInput: $updateInput) {
        _id
      }
    }
              `,
    variables: {
      "activityId": activityId,
      "updateInput": {
        "additionalDetails": values?.additionalDetails ?? "",
        "duration": values?.duration ?? "",
        "fee": values?.fee ?? "",
        "hoursBeforeReservationExpires": values?.hoursBeforeReservationExpires ?? "",
        "isReservationRequired": values?.isReservationRequired ?? "",
        "price": values?.price !== "0" ? Number(values?.price) : 0,
        "quota": values?.quota ?? "",
        "schedule": {
          // F"endDate": values?.schedule?.endDate ?? "",
          // "startDate": values?.schedule?.startDate ?? "",
          "days": values?.schedule?.days ?? "",
          "totalDayCount": values?.schedule?.totalDayCount ?? "",
          "repeatedIntervalType": values?.schedule?.repeatedIntervalType ?? "",
        }
      }
    }

  })

  return data

}

const createCategory = ({ categoryId, defaultSorting, description, imageUrl }) => {

  const data = JSON.stringify({
    query: `mutation Moderator_createCategory($input: CategoryInput!) {
      moderator_createCategory(input: $input) {
        _id
      }
    }`,
    variables: {
      "input": {
        "categoryId": categoryId,
        "defaultSorting": defaultSorting,
        "description": description,
        "imageUrl": imageUrl
      }
    }

  })

  return data

}

const updateCategory = ({ categoryId, defaultSorting, description, imageUrl, categoryIdPrew }) => {

  const data = JSON.stringify({
    query: `mutation Moderator_updateCategory($categoryId: String!, $input: UpdateCategoryInput!) {
      moderator_updateCategory(categoryId: $categoryId, input: $input) {
        _id
      }
    }`,
    variables: {
      "categoryId": categoryIdPrew,
      "input": {
        "categoryId": categoryId,
        "defaultSorting": defaultSorting,
        "description": description,
        "imageUrl": imageUrl
      }
    }

  })

  return data

}

const removeCategory = ({ categoryId }) => {

  const data = JSON.stringify({
    query: `mutation Moderator_removeCategory($categoryId: String!) {
      moderator_removeCategory(categoryId: $categoryId) {
        success
      }
    }`,
    variables: {
      "categoryId": categoryId
    }

  })

  return data

}

const updateSettings = ({ dashboardComponents }) => {

  const data = JSON.stringify({
    query: `mutation Moderator_updateSettings($input: UpdateSettingsInput!) {
      moderator_updateSettings(input: $input) {
        _id
      }
    }`,
    variables: {
      "input": {
        "dashboardComponents": dashboardComponents,
      }
    }

  })

  return data

}


export const activitiesForModerator = {
  createActivity,
  removeActivity,
  updateActivity,

  makeActive,
  makePassive,

  createCategory,
  updateCategory,
  removeCategory,

  updateSettings
}