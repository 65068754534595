/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Typography, Box, Link, Card, Stack, MenuItem, IconButton, ListItemText, List, Tooltip } from '@mui/material';

import Iconify from 'src/components/iconify';
import { fCurrency } from 'src/utils/format-number';
import { MyMiniSlider } from 'src/hophop/components';
import CustomPopover, { usePopover } from 'src/components/custom-popover';


export default function ActivityCard({ tour, onEdit, onDelete, onMakeActive, onMakePassive }) {

  const popover = usePopover();
  const rejectionDetailPopover = usePopover();


  const activityStatus = [
    ...tour?.listing?.status !== "approved" ? [
      {
        name: tour?.listing?.status === "rejected" ? "Listeleme reddedildi" : "Listeleme onay bekliyor",
        rejectionDetail: tour?.listing?.rejectionDetail,
        color: tour?.listing?.status === "rejected" ? "error.main" : "warning.main",
      }
    ] : [],

    ...tour?.location?.status !== "approved" ? [
      {
        name: tour?.location?.status === "rejected" ? "Konum reddedildi" : "Konum onay bekliyor",
        rejectionDetail: tour?.location?.rejectionDetail,
        color: tour?.location?.status === "rejected" ? "error.main" : "warning.main",
      }
    ] : []
  ]


  const isPending =
    ((tour?.listing?.status === "pendingApproval" || tour?.location?.status === "pendingApproval") && "pendingApproval")
    ||
    ((tour?.listing?.status === "rejected" || tour?.location?.status === "rejected") && "rejected")
    ||
    "approved"



  const renderPrice = (

    <Stack
      direction="row"
      gap={1}
      sx={{
        top: 8,
        left: 8,
        zIndex: 9,
        position: 'absolute',
        backgroundColor: (tour?.status === "passive" || isPending !== "approved") ? "#ffffff99" : "",
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        right: 8,
        p: 1,
        borderRadius: 1,

      }}
    >

      <Stack
        direction="row"
        alignItems="center"
        sx={{

          borderRadius: 1,
          bgcolor: 'grey.800',
          p: '2px 6px 2px 4px',
          color: 'common.white',
          typography: 'subtitle2',
        }}
      >
        {
          !!tour?.priceSale && ( // indirim öncesi hali varsa
            <Box component="span" sx={{ color: 'grey.500', mr: 0.25, textDecoration: 'line-through' }}>
              {fCurrency(tour?.priceSale)}
            </Box>
          )
        }
        ₺{tour?.price}
      </Stack>

      {
        (tour?.status === "passive") && (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              borderRadius: 1,
              p: '2px 6px 2px 4px',
              typography: 'subtitle2',
              bgcolor: 'gray',
              color: 'common.white',
            }}
          >
            Pasif
          </Stack>
        )
      }



      <Stack
        direction="column"
        onClick={(e) => {
          rejectionDetailPopover.onOpen(e)
        }}
        sx={{
          borderRadius: 1,
          p: '2px 6px 2px 4px',
          typography: 'subtitle2',
          bgcolor: isPending === "approved" ? "transparent" : (isPending === "pendingApproval" ? "warning.main" : "error.main"),
          color: 'common.white',
          cursor: 'pointer',

          '& span': {
            fontSize: '10px',
            display: 'block'

          }
        }}
      >
        {isPending === "approved" ? "" : (isPending === "pendingApproval" ? "Onay Bekliyor" : "Reddedildi")}
      </Stack>

      <CustomPopover
        open={rejectionDetailPopover.open}
        onClose={rejectionDetailPopover.onClose}
        arrow="bottom-center"
        sx={{
          // width: 140,
          p: 2
        }}
      >
        {
          activityStatus?.map((item, index) => (
            <Stack key={index} component={List}>
              <ListItemText
                primary={item.name}
                secondary={item.rejectionDetail}
              />
            </Stack>
          ))
        }
      </CustomPopover>

    </Stack>
  );

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
        {renderPrice}

        <MyMiniSlider
          list={[tour?.listing?.coverImage, ...(tour?.listing?.images ?? [])]}
          renderItem={(item) => (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url('${item}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          )}
          containerStyle={{
            height: 250
          }}
        />

      </Stack>
    </Stack>
  );

  const renderTexts = (
    <ListItemText
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
      primary={
        isPending !== "rejected"
          ? `Oluşturulma Tarihi: ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.createdAt))}`
          : (
            activityStatus?.map((item, index) => (
              <Tooltip title={item.rejectionDetail} placement='top' key={index}>
                <Typography
                  sx={{
                    fontSize: 10,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: '#E00101',
                    backgroundColor: '#E0010122',
                    borderRadius: 1,
                    p: '5px 10px',
                    mb: 1,
                  }}
                >
                  ** {item.rejectionDetail}
                </Typography>
              </Tooltip>
            ))
          )
      }
      secondary={
        <Link component={Typography} color="inherit" sx={{ textTransform: 'capitalize' }}>
          {tour?.title?.slice(0, 20)} {tour?.title?.length > 20 && "..."}
        </Link>
      }
      primaryTypographyProps={{
        typography: 'caption',
        color: 'text.disabled',
      }}
      secondaryTypographyProps={{
        mt: 1,
        noWrap: true,
        component: 'span',
        color: 'text.primary',
        typography: 'subtitle1',
      }}
    />
  );

  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
      }}
    >
      <IconButton disabled={isPending === "pendingApproval"} onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 20, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      {[
        {
          label: tour?.listing?.format !== "faceToFace" ? "Online" : tour?.location?.address?.title,
          icon: <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />,
        },
        {
          label: `
          ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.schedule?.startDate) - 1)} 
          
          ${tour?.schedule?.startDate !== tour?.schedule?.endDate ? ` -  ${new Intl.DateTimeFormat("tr", { dateStyle: "medium" }).format(new Date(tour?.schedule?.endDate) - 1)}` : ""}`,
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        (isPending !== "rejected" && {
          label: tour.quota < 99999 ? `${tour?.quota} kişi (Kontenjan)` : "Sınırsız Kontenjan",
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
        }),
      ].map((item, index) => (
        <Stack
          key={index}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          {item.icon}
          {item.label}
        </Stack>
      ))}
    </Stack>
  );


  return (
    <>
      <Card
        sx={{
          ...((tour?.status === "passive" || isPending !== "approved") && {
            backgroundColor: '#f1f1f1',
            border: '1px solid #ddd'
          }),

          ...((tour?.status === "active" && isPending === "approved") && {
            boxShadow: '1px 1px 20px 0px #aaa'
          }),
        }}
      >
        {renderImages}

        {renderTexts}

        {renderInfo}
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {
          tour?.status === "passive" ? (
            <>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onMakeActive();
                }}
              >
                <Iconify icon="heroicons-solid:status-online" />
                Aktif Yap
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onEdit();
                }}
              >
                <Iconify icon="solar:pen-bold" />
                Düzenle
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onDelete();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon="solar:trash-bin-trash-bold" />
                Sil
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onMakePassive();
                }}
              >
                <Iconify icon="heroicons-solid:status-offline" />
                Pasif Yap
              </MenuItem>

              <MenuItem
                onClick={() => {
                  popover.onClose();
                  onEdit();
                }}
              >
                <Iconify icon="solar:pen-bold" />
                Düzenle
              </MenuItem>
            </>
          )
        }
      </CustomPopover>

    </>
  );
}

ActivityCard.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onMakeActive: PropTypes.func,
  onMakePassive: PropTypes.func,
  tour: PropTypes.object,
};
