const allReservations = ({ variables, activityId }) => {

  const data = JSON.stringify({
    query: `
        query Organizer_reservations($filter: FilterReservationByOrganizerInput, $pagination: Pagination) {
            organizer_reservations(filter: $filter, pagination: $pagination) {
              count
              data {
                _id
                activity {
                  _id
                  listing {
                    _id
                    title
                    category
                    subCategory
                    ageInterval
                    format
                    description
                    images
                    coverImage
                    companionship
                  }
                  schedule {
                    _id
                    repeatedIntervalType
                    startDate
                    endDate
                    totalDayCount
                    days {
                      _id
                      activity {
                        _id
                      }
                      date
                      day
                      startTime
                      endTime
                      createdAt
                      updatedAt
                    }
                    createdAt
                    updatedAt
                  }
                  location {
                    _id
                    city
                    district
                    country
                    address {
                      title
                      description
                    }
                    lat
                    long
                    parking
                  }
                  additionalDetails
                  quota
                  hoursBeforeReservationExpires
                  isReservationRequired
                  fee
                  price
                  duration
                  status
                  createdBy {
                    _id
                    displayName
                    displayImageUrl
                    description
                    # role
                  }
                  updatedBy {
                    _id
                    displayName
                    displayImageUrl
                    description
                  #  role
                  }
                  createdAt
                  updatedAt
                }
                parent {
                  _id
                  displayName
                  displayImageUrl
                  description
                #  role
                }
                 status
                imageUrl
                # cancellationInformation {
                #   _id
                #   description
                #   at
                #   createdAt
                #   updatedAt
                # }
                # rejectionInformation {
                #   _id
                #   description
                #   at
                #   createdAt
                #   updatedAt
                # }
                createdAt
                updatedAt
              }
            }
          }
        `,
    variables: {
      "filter": {
        "activityId": activityId ?? null,
        "dateInterval": {
          "from": null,
          "to": null
        },
        "status": variables?.filter?.status
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null,
      }
    }
  })

  return data
}

const reservationDetail = ({ reservationId }) => {

  const data = JSON.stringify({
    query: `
        
        `,
    variables: {

    }
  })

  return data
}

export const reservationsForOrganizer = {
  allReservations,
  reservationDetail
}