/* eslint-disable arrow-body-style */
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Card, Typography, Alert } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { compressImage } from 'src/hophop/utils';
import { useSnackbar } from 'src/components/snackbar';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';



export default function AccountGeneral() {

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { handleFetchData } = useFetchData()
  const [userInfo, setUserInfo] = useState(null)
  const { user: user_user } = useSelector(state => state.user)
  const { payment_user, loading: payment_loading } = useSelector(state => state.payment)
  const { accessToken, user: authUser } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(reduxFunc.paymentFunc.fetchPaymentUserInfoFunc({ accessToken }))
    dispatch(reduxFunc.userFunc.fetchUserInfoFunc({ accessToken, userId: authUser?.userId }))
  }, [accessToken, dispatch, authUser?.userId])

  useEffect(() => {
    setUserInfo({ ...user_user, ...payment_user })
  }, [payment_user, user_user])


  const UpdateUserSchema = Yup.object().shape({

    companyType: Yup.string("")
      .required("Zorunlu alan!"),
    fullName: Yup.string("")
      .required('Zorunlu alan')
      .test('len', 'En az 2 kelime olmalı', (val) => val?.trim().split(" ").length > 1),
    taxNo: Yup.string()
      .required('Zorunlu alan'),
    identificationNumber: Yup.string()
      .required('Zorunlu alan')
      .max(11, "Geçerli bir TC kimlik numarası giriniz")
      .min(11, "Geçerli bir TC kimlik numarası giriniz"),
    taxOffice: Yup.string()
      .required('Zorunlu alan')
      .min(3, "Geçerli bir vergi dairesi giriniz!"),
    iban: Yup.string()
      .trim()
      .max(26, 'Geçersiz iban')
      .test('valid-iban', 'Geçersiz iban', (value) => {
        if (value.length === 0) return true
        if (!(/^[A-Z]{2}\d{2}[A-Z0-9]+$/.test(value))) return false
        if (value.length < 26) return false
        return () => null
      }),
    organization: Yup.string("")
      .required("Zorunlu alan!"),
    email: Yup.string()
      .email("Geçerli bir email giriniz")
      .required('Zorunlu alan')
      .test('len', 'xxxx@xxxxx.xxx şeklinde yazılması gerekiyor', (val) => val?.trim().split(/[@.]+/).length > 2),
    phoneNumber: Yup.string()
      .required('Zorunlu alan')
      .min(10, "Geçerli bir telefon numarası giriniz! (xxx xxxxxxx)")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{4,4}?$/,
        "Geçerli bir telefon numarası giriniz! (xxx xxx xxxx)"
      ),
    phoneNumber2: Yup.string()
      .min(10, "Geçerli bir telefon numarası giriniz! (xxx xxxxxxx)")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{4,4}?$/,
        "Geçerli bir telefon numarası giriniz! (xxx xxx xxxx)"
      ),
    address: Yup.string()
      .min(5, "Geçerli bir adres giriniz")
      .required('Zorunlu alan'),
    profileImageUrl: Yup.string()
      .required('Zorunlu alan!')
      .nullable(true),
    description: Yup.string()
      .required('Zorunlu alan'),

    website: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    instagram: Yup.string(),
    facebook: Yup.string(),

  });

  const defaultValues = useMemo(() => ({

    profileImageUrl: userInfo?.personalInformation?.profileImageUrl || null,

    companyType:
      (!userInfo?.taxOffice && "personal") ||
      (userInfo?.identityNumber && "privateCompany") ||
      "limitedCompany",

    fullName: userInfo?.personalInformation?.fullName || '',
    description: userInfo?.personalInformation?.description || '',
    email: userInfo?.contact?.email || userInfo?.email || "",
    phoneNumber: userInfo?.contact?.phoneNumber || userInfo?.gsmNumber || "",
    phoneNumber2: userInfo?.contact?.phoneNumber2 || "",
    address: userInfo?.address || userInfo?.contact?.address?.content?.description || "",
    iban: userInfo?.iban ?? "",
    swiftCode: userInfo?.swiftCode ?? "",

    identificationNumber: userInfo?.identityNumber || '',
    organization: userInfo?.personalInformation?.organization || '',
    taxOffice: userInfo?.personalInformation?.taxOffice || userInfo?.taxOffice || "",
    taxNo: userInfo?.personalInformation?.taxNo || userInfo?.taxNo || "",

    youtube: userInfo?.socialMedia?.youtube || "",
    website: userInfo?.socialMedia?.website || "",
    twitter: userInfo?.socialMedia?.twitter || "",
    instagram: userInfo?.socialMedia?.instagram || "",
    facebook: userInfo?.socialMedia?.facebook || "",

  }), [userInfo]);

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
    mode: "all"
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    watch,
  } = methods;

  const values = watch()

  useEffect(() => {
    reset({ ...defaultValues });
  }, [userInfo, reset, defaultValues]);

  const onSubmit = async (e) => {
    e.preventDefault()

    const control = (
      (!userInfo?.taxOffice && "personal") ||
      (userInfo?.identityNumber && "privateCompany") ||
      "limitedCompany"
    ) !== values?.companyType

    if (control) {
      if (!window.confirm("Şirket tipi değiştirmek istediğinize emin misiniz?")) return
    }

    let uploadUrl;

    try {

      if (typeof values?.profileImageUrl !== "string") {

        uploadUrl = await graphqlApi.dms.organizationPresignedUrls.logoImageOrganizationPresignedUrls({
          organizationId: userInfo?._id,
          file: values.logo,
          accessToken
        })

        const compressedImage = await compressImage(values?.profileImageUrl, 0.4)

        const imageUrl = await graphqlApi.dms.handleSaveImage({
          file: compressedImage,
          url: uploadUrl?.presignedUploadUrl
        })

      }

      const response = await handleFetchData({
        data: graphqlApi.user.mutations.userLMutations.userUpdate({
          values,
          profileImageUrl: (typeof values?.profileImageUrl !== "string") ? uploadUrl?.url : values?.profileImageUrl,
        }),
        url: "/user/graphql",
        accessToken,
      });


      if (response?.data?.data?.updateUser?._id) {

        enqueueSnackbar('Profil güncellemesi yapıldı!');
        dispatch(reduxFunc.userFunc.fetchUserInfoFunc({ accessToken, userId: authUser?.userId }))


        if (values?.companyType === "personal") {

          const responseIyzico = await handleFetchData({
            data: graphqlApi.payment.mutations.personalUpdate({
              values: {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.phoneNumber,
                "iban": values?.iban,
                "identityNumber": values?.identificationNumber,
                "name": values?.fullName,
                "contactName": values?.fullName?.split(" ")?.[0],
                "contactSurname": values?.fullName?.split(" ")?.[1],
                "swiftCode": values?.swiftCode,
              }
            }),
            url: "/payment/graphql",
            accessToken,

          });



          if (responseIyzico?.data?.data?.self_updateIyzicoPersonalSubMerchantInfo?.success) {
            dispatch(reduxFunc.paymentFunc.fetchPaymentUserInfoFunc({ accessToken }))
            enqueueSnackbar('İyzico güncellemesi başarılı!');
          } else {
            enqueueSnackbar('Ödeme işlemleri için IBAN bilgisini girmeniz gerekmektedir!', { variant: 'warning' });
          }

        } else if (values?.companyType === "privateCompany") {

          const responseIyzico = await handleFetchData({
            data: graphqlApi.payment.mutations.privateCompanyUpdate({
              values: {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.phoneNumber,
                "iban": values?.iban,
                "identityNumber": values?.identificationNumber,
                "name": values?.fullName,
                // "contactName": values?.fullName,
                "swiftCode": values?.swiftCode,

                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.organization,
              }
            }),
            url: "/payment/graphql",
            accessToken,

          });



          if (responseIyzico?.data?.data?.self_updateIyzicoPrivateCompanySubMerchantInfo?.success) {
            dispatch(reduxFunc.paymentFunc.fetchPaymentUserInfoFunc({ accessToken }))
            enqueueSnackbar('İyzico güncellemesi başarılı!');
          } else {
            enqueueSnackbar('Ödeme işlemleri için IBAN bilgisini girmeniz gerekmektedir!', { variant: 'warning' });
          }

        } else {

          const responseIyzico = await handleFetchData({
            data: graphqlApi.payment.mutations.limitedCompanyUpdate({
              values: {
                "address": values?.address,
                "email": values?.email,
                "gsmNumber": values?.phoneNumber,
                "iban": values?.iban,
                // "identityNumber": values?.identificationNumber,
                "name": values?.fullName,
                "swiftCode": values?.swiftCode,

                "taxOffice": values?.taxOffice,
                "legalCompanyTitle": values?.organization,
                "taxNumber": values?.taxNo,
              }
            }),
            url: "/payment/graphql",
            accessToken,

          });



          if (responseIyzico?.data?.data?.self_updateIyzicoLimitedCompanySubMerchantInfo?.success) {
            dispatch(reduxFunc.paymentFunc.fetchPaymentUserInfoFunc({ accessToken }))
            enqueueSnackbar('İyzico güncellemesi başarılı!');
          } else {
            enqueueSnackbar('Ödeme işlemleri için IBAN bilgisini girmeniz gerekmektedir!', { variant: 'warning' });
          }

        }


      } else {
        enqueueSnackbar('Güncelleme yapılamadı!', { variant: 'error' });
      }

    } catch (error) {
      console.error(error);
      enqueueSnackbar('Birşeyler yanlış gitti!', { variant: 'error' });
    }

  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];


      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profileImageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );



  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>


        {
          (!payment_user?.iban && !payment_loading) && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Ödeme işlemleri için IBAN bilgisini girmeniz gerekmektedir!
              </Alert>
            </Grid>
          )
        }


        <Grid item xs={12} md={4} >
          <Card sx={{ p: 3, textAlign: 'center' }}>
            <Grid container spacing={3}>


              <Grid item xs={12}>
                <RHFUploadAvatar
                  name="profileImageUrl"
                  maxSize={3145728}
                  onDrop={handleDrop}
                />            </Grid>

              <Grid item xs={12}>
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {userInfo?.personalInformation?.fullName}
                </Typography>
              </Grid>




              <Grid item xs={12}>
                <RHFTextField name="website" label="Web Sitesi" />
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="youtube" label="Youtube" />
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="twitter" label="Twitter" />
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="instagram" label="Instagram" />
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="facebook" label="Facebook" />
              </Grid>

            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={3}>

              {/* <Grid item xs={12} sm={6}>
                <RHFSelect name="companyType" label="Şirket Tipi" disabled>
                  {
                    [
                      { id: 1, name: 'Şahıs Organizasyonu', value: "personal" },
                      { id: 2, name: 'Şahıs Şirketi', value: "privateCompany" },
                      { id: 3, name: 'Limited / Anonim Şirketi', value: "limitedCompany" },
                    ].map((status, i) => (
                      <MenuItem key={`${status?.value}-${i}`} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                        {status?.name?.toLocaleLowerCase()}
                      </MenuItem>
                    ))
                  }
                </RHFSelect>
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <RHFTextField name="fullName" label="İsim" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFTextField name="email" label="Email Adresi" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFTextField name="phoneNumber" label="Telefon Numarası" placeholder="--- -------" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFTextField name="phoneNumber2" label="Telefon Numarası 2" placeholder="--- -------" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <RHFTextField
                  name="iban"
                  label="IBAN"
                  placeholder="TR-- ---- ---- ---- ---- ---- --"
                  sx={{
                    borderColor: (!payment_user?.iban && !payment_loading) ? "yellow" : "",
                    '& *': {
                      borderColor: (!payment_user?.iban && !payment_loading) ? "warning.light" : "",
                    }
                  }}
                />
              </Grid>


              {
                values?.companyType === "personal" && (
                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="identificationNumber" label="TC. No." />
                  </Grid>
                )

              }

              {

                values?.companyType === "privateCompany" &&
                <>

                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="organization" label="Şirket Adı" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="taxOffice" label="Vergi Dairesi" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="identificationNumber" label="TC. No." />
                  </Grid>

                </>
              }

              {
                values?.companyType === "limitedCompany" &&
                <>
                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="organization" label="Şirket Adı" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="taxOffice" label="Vergi Dairesi" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <RHFTextField name="taxNo" label="Vergi Numarası" />
                  </Grid>
                </>

              }


              <Grid item xs={12}>
                <RHFTextField name="description" label="*Organizatör Hakkında" multiline rows={3} inputProps={{ maxLength: 300 }} />
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="address" multiline rows={4} label="Adres" />
              </Grid>


              <Grid item xs={12} >
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  onClick={onSubmit}

                  disabled={!!(
                    // !dirtyFields.companyType || errors.companyType
                    errors.fullName
                    || errors.organization
                    || errors.email
                    || errors.phoneNumber
                    || errors.logo
                    || errors.taxOffice
                    || (errors.taxNo) && (errors.identityNumber)
                    || errors.iban
                    || errors.swiftCode
                    || errors.address
                  )}
                >
                  Kaydet
                </LoadingButton>
              </Grid>

            </Grid>
          </Card>
        </Grid>

      </Grid >
    </FormProvider >
  );
}
