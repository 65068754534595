const conservations = ({ variables }) => {

  const data = JSON.stringify({
    query: `
        query User_activityConversations($pagination: Pagination) {
            user_activityConversations(pagination: $pagination) {
              count
              data {
                activity {
                  activityId {
                    _id
                  }
                  exteriorOrganizer {
                    _id
                    name
                    imageUrl
                    details
                    createdAt
                    updatedAt
                    contact {
                    phoneNumber
                    phoneNumber2
                    }
                  }
                  title
                  coverImage
                  createdBy {
                    _id
                  }
                }
                lastMessage {
                  _id
                  sender {
                    _id
                  }
                  receiver {
                    _id
                  }
                  activity {
                    _id
                  }
                  content
                  attachments
                  senderHasSend
                  receiverHasReceived
                  receiverHasRead
                  createdAt
                  updatedAt
                }
                receiver {
                  _id
                  displayName
                  displayImageUrl
                  description
                  role
                }
              }
            }
          }
        `,
    variables: {
      "pagination": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize,
        "sort": null
      },

    }
  })

  return data

}

const selectedUserMessages = ({ variables }) => {

  const data = JSON.stringify({
    query: `
      query User_messages($receiverId: String!, $activityId: String!, $filter: FilterMessageInput, $pagination: Pagination) {
  
        user_messages(receiverId: $receiverId, activityId: $activityId, filter: $filter, pagination: $pagination) {
          count
          data {
            _id
            activity {
              _id
            }
            attachments
            content
            createdAt
            receiver {
              displayImageUrl
              displayName
            }
            receiverHasRead
            receiverHasReceived
            sender {
              _id
              description
              displayImageUrl
              displayName
              role
            }
            senderHasSend
            updatedAt
          }
        }
  
  
      }
      `,
    variables: {
      "receiverId": variables?.receiverId,
      "activityId": variables?.activityId,
      "filter": {
        "dateInterval": {
          "from": null,
          "to": null
        }
      },
      "pagination": {
        "page": null,
        "pageSize": null
      }
    }
  })

  return data

}

export const messagesForOrganizer = {
  conservations,
  selectedUserMessages
}