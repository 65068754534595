const createBlog = ({ values, images, coverImage }) => {

    const data = JSON.stringify({
        query: `
        mutation Moderator_createActivityBlog($activityBlogInput: ActivityBlogInput!) {
            moderator_createActivityBlog(activityBlogInput: $activityBlogInput) {
              _id
            }
        }
        `,
        variables: {
            "activityBlogInput": {
                "additionalDetails": values?.additionalDetails ?? null,
                "plus": values?.plus ?? false,
                "fee": values?.fee ?? null,

                "listing": {
                    "title": values?.title ?? null,
                    "category": values?.category ?? null,
                    ...(values?.companionship && { "companionship": values?.companionship ?? null }),
                    ...(values?.format ? { "format": values?.format } : {}),
                    // değişti test ediliyor ...(values?.ageInterval && { "ageInterval": (typeof (values?.ageInterval?.[0]) !== "string" ? values?.ageInterval?.[0] : values?.ageInterval) }),
                    ...(values?.ageInterval && { "ageInterval": (values?.ageInterval) }),

                    "description": values?.description ?? null,

                    "coverImage": coverImage ?? null,
                    "images": images ?? null,



                    // added social media and contact information
                    "exteriorOrganizer": {
                        "contact": {
                            "phoneNumber": values?.phoneNumber,
                            "phoneNumber2": values?.phoneNumber2,
                            "email": values?.email
                        },
                        "details": values?.description ?? values?.title,
                        "imageUrl": coverImage ?? null,
                        "name": values?.title ?? null,
                        "socialMedia": {
                            "facebook": values?.facebook ?? null,
                            "instagram": values?.instagram ?? null,
                            "twitter": values?.twitter ?? null,
                            "website": values?.website ?? null,
                            "youtube": values?.youtube ?? null
                        }
                    }

                },


                "location": {
                    "address": {
                        "description": values?.location?.description ?? null,
                        "title": values?.location?.title ?? null,
                    },
                    "city": values?.location?.city ?? null,
                    "district": values?.location?.district ?? null,

                    ...(values?.location?.lat && { "lat": values?.location?.lat ?? null }),
                    ...(values?.location?.long && { "long": values?.location?.long }),
                    ...(values?.location?.parking && { "parking": values?.location?.parking ?? null }),
                },
                "schedule": {
                    ...(values?.schedule?.openHour && { "openHour": values?.schedule?.openHour ?? null }),
                    ...(values?.schedule?.closeHour && { "closeHour": values?.schedule?.closeHour ?? null }),
                },
            }
        }

    })

    return data

}


const removeBlog = ({ BlogId }) => {

    const data = JSON.stringify({
        query: `
        mutation Moderator_removeActivityBlog($activityBlogId: String!) {
            moderator_removeActivityBlog(activityBlogId: $activityBlogId) {
              success
            }
          }
        `,
        variables: {
            "activityBlogId": BlogId
        }
    })

    return data

}


const updateBlog = ({ blogId, values, images, coverImage }) => {

    console.log(values.ageInterval, "values.ageInterval")

    const data = JSON.stringify({
        query: `
        mutation Moderator_updateActivityBlog($activityBlogId: String!, $updateInput: UpdateActivityBlogInput!) {
            moderator_updateActivityBlog(activityBlogId: $activityBlogId, updateInput: $updateInput) {
              _id
            }
          }
        `,
        variables: {
            "activityBlogId": blogId,
            "updateInput": {

                "additionalDetails": values?.additionalDetails ?? null,
                "fee": values?.fee ?? null,


                "listing": {
                    "title": values?.title ?? null,
                    "category": values?.category ?? null,
                    ...(values?.companionship && { "companionship": values?.companionship ?? null }),
                    ...(values?.format ? { "format": values?.format } : {}),
                    // değişti test ediliyor ...(values?.ageInterval && { "ageInterval": (typeof (values?.ageInterval?.[0]) !== "string" ? values?.ageInterval?.[0] : values?.ageInterval) }),
                    ...(values?.ageInterval && { "ageInterval": (values?.ageInterval) }),

                    "description": values?.description ?? null,

                    "coverImage": coverImage ?? null,
                    "images": images ?? null,


                    // added social media and contact information
                    "exteriorOrganizer": {
                        "contact": {
                            "phoneNumber": values?.phoneNumber,
                            "phoneNumber2": values?.phoneNumber2,
                        },
                        "details": values?.description ?? values?.title,
                        "imageUrl": coverImage ?? null,
                        "name": values?.title ?? null,
                        "socialMedia": {
                            "facebook": values?.facebook ?? null,
                            "instagram": values?.instagram ?? null,
                            "twitter": values?.twitter ?? null,
                            "website": values?.website ?? null,
                            "youtube": values?.youtube ?? null
                        }
                    }


                },


                "location": {
                    "address": {
                        "description": values?.location?.description ?? null,
                        "title": values?.location?.title ?? null,
                    },
                    "city": values?.location?.city ?? null,
                    "district": values?.location?.district ?? null,

                    ...(values?.location?.lat && { "lat": values?.location?.lat ?? null }),
                    ...(values?.location?.long && { "long": values?.location?.long }),
                    ...(values?.location?.parking && { "parking": values?.location?.parking ?? null }),
                },

                "schedule": {
                    ...(values?.schedule?.openHour && { "openHour": values?.schedule?.openHour ?? null }),
                    ...(values?.schedule?.closeHour && { "closeHour": values?.schedule?.closeHour ?? null }),
                },
            }
            // "updateInput": {

            //     "additionalDetails": values?.additionalDetails ?? "",
            //     "ageInterval": values?.ageInterval ?? [],
            //     "category": values?.category ?? "",
            //     "companionship": values?.companionship ?? "",
            //     "coverImage": coverImage ?? "",
            //     "description": values?.description ?? "",
            //     "duration": values?.duration ?? 0,
            //     "format": values?.format ?? "",
            //     "images": images ?? [],
            //     //   "locationId": values?.locationId ?? "",

            //     "schedule": {
            //         "days": values?.schedule?.days ?? [],
            //         "repeatedIntervalType": values?.schedule?.repeatedIntervalType !== "" ? values?.schedule?.repeatedIntervalType : "once",
            //         "totalDayCount": values?.schedule?.totalDayCount ?? "",
            //         // "endDate": values?.schedule?.endDate ?? "",
            //         // "startDate": values?.schedule?.startDate ?? "",
            //     },
            //     "subCategory": values?.subCategory ?? "",
            //     "title": values?.title ?? "",
            // }
        }

    })

    return data

}


export const blogsForModerator = {
    createBlog,
    removeBlog,
    updateBlog,
}