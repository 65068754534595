/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import animateScrollTo from 'animated-scroll-to';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  Box,
  Grid,
  Card,
  Step,
  Chip,
  Stack,
  Dialog,
  Stepper,
  MenuItem,
  Container,
  StepLabel,
  IconButton,
  Typography,
  Button
} from '@mui/material';

import ChecklistIcon from '@mui/icons-material/Checklist';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { BusinessCenterOutlined, Person2Outlined, RemoveRedEye } from '@mui/icons-material';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { compressImage } from 'src/hophop/utils';
import { useSnackbar } from 'src/components/snackbar';
import { MultiFilePreview } from 'src/components/upload';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { RHFAutocomplete, RHFAutocomplete2, RHFSelect, RHFTextField, RHFUpload } from 'src/components/hook-form';
import {
  ageIntervalsForListing,
  companionshipsForListing,
  formatsForListing,
} from 'src/hophop/utils/_enums';
import CreateListingCard from './_CreateListingCard';


const steps = ['Başlangıç', 'Temel Bilgiler', 'Detay Bilgiler', 'Bitiş'];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  transitionDuration: '1s',

  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    transitionDuration: '1s',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7DC9CF',
      transitionDuration: '1s',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7DC9CF',
      transitionDuration: '1s',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    transitionDuration: '1s',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#7DC9CF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#7DC9CF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  transitionDuration: '1s',
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PlayCircleOutlineOutlinedIcon />,
    2: <Person2Outlined />,
    3: <BusinessCenterOutlined />,
    4: <OutlinedFlagRoundedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props?.icon)]}
    </ColorlibStepIconRoot>
  );
}

const CreateListing = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const { handleFetchData } = useFetchData();
  const [isReview, setIsReview] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { accessToken } = useSelector((state) => state?.auth);
  const [createListingLoading, setCreateListingLoading] = useState(false)
  const { selectedListingForModerator } = useSelector((state) => state?.listing);
  const { allUser: list } = useSelector(state => state.user)
  const [selectedOrganizer, setSelectedOrganizer] = useState(null)
  const [variables, setVariables] = useState({
    "filter": {
      "role": "organizer",
    }
  })


  const handleNext = useCallback(() => {
    let newSkipped = skipped;
    if (skipped.has(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }, [activeStep, skipped]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(reduxFunc.parameterFunc.fetchParametersFunc({ accessToken }));
      if (id) {
        dispatch(
          reduxFunc.listingFunc.fetchSelectedListingForModeratorFunc({ accessToken, listingId: id })
        );
      } else dispatch(reduxFunc.listingFunc.resetSelectedListing());
    }
  }, [accessToken, dispatch, id]);

  const NewBlogSchema = Yup.object().shape({
    category: Yup.array().min(1, 'En az bir tane seçilmeli!').required('Zorunlu alan!'),
    title: Yup.string().required('Zorunlu alan!'),
    description: Yup.string().required('Zorunlu alan!'),
    coverImage: Yup.string().required('Zorunlu alan!'),

    ageInterval: Yup.array().min(1, 'En az bir tane seçilmeli!').required('Zorunlu alan!'),
    companionship: Yup.string().required('Zorunlu alan!'),
    format: Yup.string().required('Zorunlu alan!'),
    images: Yup.array().min(1, 'En az 1 resim olmalı').max(5, 'En fazla 5 resim olmalı!'),

    exteriorOrganizerImageUrl: Yup.string().notRequired(),
    exteriorOrganizerName: Yup.string().required('Zorunlu alan!'),
    exteriorOrganizerDetails: Yup.string().required('Zorunlu alan!'),

    facebook: Yup.string(),
    instagram: Yup.string(),
    twitter: Yup.string(),
    website: Yup.string(),
    youtube: Yup.string(),
    phoneNumber: Yup.string(),
    phoneNumber2: Yup.string(),
    //  //todo düzelt
  });

  console.log(selectedOrganizer?.contact.phoneNumber, "selectedOrganizer")
  console.log(selectedListingForModerator?.exteriorOrganizer?.contact?.phoneNumber, "selectedListingForModerator")

  const defaultValues = useMemo(
    () => ({
      category: selectedListingForModerator?.category ?? [],
      title: selectedListingForModerator?.title ?? '',
      description: selectedListingForModerator?.description ?? '',
      coverImage: selectedListingForModerator?.coverImage ?? null,

      ageInterval: selectedListingForModerator?.ageInterval
        ? selectedListingForModerator?.ageInterval?.map((item) =>
          ageIntervalsForListing.find((age) => age?.value === item)
        )
        : [],
      companionship: selectedListingForModerator?.companionship ?? '',
      format: selectedListingForModerator?.format ?? '',
      images: selectedListingForModerator?.images,
      // .map(item => ({
      //     preview: item,
      //     name: item,
      //     path: item
      // })) ?? [],
      subCategory: selectedListingForModerator?.subCategory ?? 'activity',
      type: selectedListingForModerator?.type ?? 'activity',

      exteriorOrganizerDetails: selectedListingForModerator?.exteriorOrganizer?.details ? selectedListingForModerator?.exteriorOrganizer?.details : selectedOrganizer?.personalInformation?.description ? selectedOrganizer?.personalInformation?.description : '',
      exteriorOrganizerImageUrl: selectedListingForModerator?.exteriorOrganizer?.imageUrl ? selectedListingForModerator?.exteriorOrganizer?.imageUrl : selectedOrganizer?.personalInformation?.profileImageUrl ? selectedOrganizer?.personalInformation?.profileImageUrl : '',
      exteriorOrganizerName: selectedListingForModerator?.exteriorOrganizer?.name ? selectedListingForModerator?.exteriorOrganizer?.name : selectedOrganizer?.personalInformation?.organization ? selectedOrganizer?.personalInformation?.organization : '',

      facebook: selectedListingForModerator?.exteriorOrganizer?.socialMedia?.facebook ? selectedListingForModerator?.exteriorOrganizer?.socialMedia?.facebook : selectedOrganizer?.socialMedia?.facebook ? selectedOrganizer?.socialMedia?.facebook : '',
      instagram: selectedListingForModerator?.exteriorOrganizer?.socialMedia?.instagram ? selectedListingForModerator?.exteriorOrganizer?.socialMedia?.instagram : selectedOrganizer?.socialMedia?.instagram ? selectedOrganizer?.socialMedia?.instagram : '',
      twitter: selectedListingForModerator?.exteriorOrganizer?.socialMedia?.twitter ? selectedListingForModerator?.exteriorOrganizer?.socialMedia?.twitter : selectedOrganizer?.socialMedia?.twitter ? selectedOrganizer?.socialMedia?.twitter : '',
      website: selectedListingForModerator?.exteriorOrganizer?.socialMedia?.website ? selectedListingForModerator?.exteriorOrganizer?.socialMedia?.website : selectedOrganizer?.socialMedia?.website ? selectedOrganizer?.socialMedia?.website : '',
      youtube: selectedListingForModerator?.exteriorOrganizer?.socialMedia?.youtube ? selectedListingForModerator?.exteriorOrganizer?.socialMedia?.youtube : selectedOrganizer?.socialMedia?.youtube ? selectedOrganizer?.socialMedia?.youtube : '',
      phoneNumber: selectedListingForModerator?.exteriorOrganizer?.contact?.phoneNumber ? selectedListingForModerator?.exteriorOrganizer?.contact?.phoneNumber : selectedOrganizer?.contact?.phoneNumber ? selectedOrganizer?.contact?.phoneNumber : '',
      phoneNumber2: selectedListingForModerator?.exteriorOrganizer?.contact?.phoneNumber2 ? selectedListingForModerator?.exteriorOrganizer?.contact?.phoneNumber2 : selectedOrganizer?.contact?.phoneNumber2 ? selectedOrganizer?.contact?.phoneNumber2 : '',
    }),
    [selectedListingForModerator, selectedOrganizer]
  );

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    watch,
    handleSubmit,
    trigger,
    setValue,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, id, reset]);

  const values = watch();

  const handleCreate = async () => {
    try {
      // ############# cover Image  ###############
      const coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
        listingId: values.title,
        file: values.coverImage,
        accessToken,
      });
      const compressedCoverImage = await compressImage(values.coverImage, 0.6)

      await graphqlApi.dms.handleSaveImage({
        file: compressedCoverImage,
        url: coverImageUploadUrl?.presignedUploadUrl,
      });

      const coverImageUrl = coverImageUploadUrl.url;

      // ############# images  ###############
      const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
        listingId: values.title,
        file: values?.images,
        accessToken,
        imageCount: values?.images?.length,
      });

      await imageUploadUrls.forEach(async (item, i) => {
        const comppressedImages = await compressImage(values.images[i], 0.6)

        await graphqlApi.dms.handleSaveImage({
          file: comppressedImages,
          url: item?.presignedUploadUrl,
        });
      });

      const imageUrls = imageUploadUrls.map((item) => item.url);

      // ############# exterior organizer imageUrl  ###############

      let exteriorOrganizerImageUrl
      if (!selectedOrganizer?._id && values?.exteriorOrganizerImageUrl) {
        const exteriorOrganizerUploadImageUrl =
          await graphqlApi.dms.listingImagesPresignedUrls.listingExteriorOrgImagePresignedUrl({
            listingId: values.title,
            file: values.exteriorOrganizerImageUrl,
            accessToken,
          });

        const compressedExtraOrganizerImage = await compressImage(values.exteriorOrganizerImageUrl, 0.6)


        await graphqlApi.dms.handleSaveImage({
          file: compressedExtraOrganizerImage,
          url: exteriorOrganizerUploadImageUrl?.presignedUploadUrl,
        });
        exteriorOrganizerImageUrl = exteriorOrganizerUploadImageUrl.url;
      } else {
        exteriorOrganizerImageUrl = selectedOrganizer?.personalInformation?.profileImageUrl
      }

      // ############# create listing  ###############
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.listingsForModerator.createListing({
          values: {
            ...values,
            ageInterval: values?.ageInterval?.map((item) => item?.value),
          },
          coverImage: coverImageUrl,
          images: imageUrls,
          exteriorOrganizerImageUrl,
        }),
        url: '/activity/graphql',
        accessToken,
      });

      console.log(response, 'response')

      if (response?.data?.data?.moderator_createListing?._id) {
        enqueueSnackbar('Kayıt başarılı!');
        handleNext();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    try {
      // ############# cover Image  ###############
      let coverImageUploadUrl;
      let coverImageUrl = null;
      if (typeof values?.coverImage !== 'string') {
        coverImageUploadUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingCoverImagePresignedUrl({
          listingId: values.title,
          file: values.coverImage,
          accessToken,
        });
        const compressedCoverImage = await compressImage(values.coverImage, 0.6)

        await graphqlApi.dms.handleSaveImage({
          file: compressedCoverImage,
          url: coverImageUploadUrl?.presignedUploadUrl,
        });

        coverImageUrl = coverImageUploadUrl.url;
      }

      // ############# images  ###############
      const prevImagesUrl = values?.images?.filter((item) => typeof item === 'string');
      const newImages = values?.images?.filter((item) => typeof item !== 'string');

      const imageUploadUrls = await graphqlApi.dms.listingImagesPresignedUrls.listingImagePresignedUrlList({
        listingId: values.title,
        file: newImages,
        accessToken,
        imageCount: newImages?.length,
      });

      await imageUploadUrls.forEach(async (item, i) => {
        const compressedImages = await compressImage(newImages[i], 0.6)

        await graphqlApi.dms.handleSaveImage({
          file: compressedImages,
          url: item?.presignedUploadUrl,
        });
      });

      const newImagesUrls = imageUploadUrls.map((item) => item.url);

      // ############# exterior organizer imageUrl  ###############
      let exteriorOrganizerUploadImageUrl;
      let exteriorOrganizerImageUrl;
      if (typeof values?.exteriorOrganizerImageUrl !== 'string') {
        exteriorOrganizerUploadImageUrl = await graphqlApi.dms.listingImagesPresignedUrls.listingExteriorOrgImagePresignedUrl({
          listingId: values.title,
          file: values.exteriorOrganizerImageUrl,
          accessToken,
        });
        const compressedExtraOrganizerImage = await compressImage(values.exteriorOrganizerImageUrl, 0.6)

        await graphqlApi.dms.handleSaveImage({
          file: compressedExtraOrganizerImage,
          url: exteriorOrganizerUploadImageUrl?.presignedUploadUrl,
        });

        exteriorOrganizerImageUrl = exteriorOrganizerUploadImageUrl.url;
      }

      // ############# create listing  ###############
      const response = await handleFetchData({
        data: graphqlApi.activity.mutations.listingsForModerator.updateModeratorListing({
          values: {
            ...values,
            ageInterval: values?.ageInterval?.map((item) => item?.value),
          },
          coverImage: coverImageUrl ?? values?.coverImage,
          images: [...prevImagesUrl, ...newImagesUrls],
          listingId: id,
          exteriorOrganizerImageUrl: exteriorOrganizerImageUrl ?? values?.exteriorOrganizerImageUrl,
        }),
        url: '/activity/graphql',
        accessToken,
      });



      if (response?.data?.data?.moderator_updateListing?.success) {
        enqueueSnackbar('Güncelleme başarılı!');
        handleNext();
      } else {
        enqueueSnackbar('Güncelleme yapılamaz!', { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {

    try {
      setCreateListingLoading(true)
      if (id) {
        await handleEdit();
      } else {
        await handleCreate();
      }
      setCreateListingLoading(false)
    } catch (error) {
      setCreateListingLoading(false)
    }

  };

  const renderOrganizerOnboardingPages = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <FirstPage />;
      case 1:
        return <FormOne setValue={setValue} list={list?.data} selectedOrganizer={selectedOrganizer} setSelectedOrganizer={setSelectedOrganizer} />;
      case 2:
        return <FormTwo values={values} setValue={setValue} />;
      case 3:
        return <LastPage />;
      default:
        return <FirstPage />;
    }
  }, [activeStep, setValue, values, list?.data, selectedOrganizer]);



  useEffect(() => {
    dispatch(reduxFunc.userFunc.fetchAllUserInfoFunc({ accessToken, variables }))
  }, [accessToken, dispatch, variables])

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <CustomBreadcrumbs
        heading={id ? 'Listeleme Güncelle' : 'Listeleme Oluştur'}
        sx={{
          my: { xs: 3, md: 5 },
        }}
        action={
          <IconButton
            variant="contained"
            onClick={() => setIsReview(true)}
            sx={{
              display: { xs: 'flex', sm: 'none' },
              position: 'fixed',
              top: 90,
              right: 20,
              zIndex: 10,
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                color: 'secondary.main',
                bgcolor: 'primary.light',
              }
            }}
          >
            {/* Ön İzleme */}
            <RemoveRedEye />
          </IconButton>
        }
      />

      <Grid container spacing={3} sx={{ mt: 5 }}>

        <Grid item xs={12} md={7}>
          <Card sx={{ p: 3 }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
              sx={{
                width: '100%',
                transition: 'all',
                transitionDuration: '1s',
                mb: 5,
              }}
            >
              {steps.map((label, index) => {
                return (
                  <Step key={label} sx={{ transitionDuration: '1s' }}>
                    <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ transitionDuration: '1s' }}>
                      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{label}</Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={3}
                sx={{
                  width: '100%',
                  height: '100%',
                  px: { sm: '10%' },
                }}
              >
                <Box>{renderOrganizerOnboardingPages()}</Box>

                <Box
                  sx={{
                    display: activeStep === 4 || activeStep === 3 ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <LoadingButton
                    loading={createListingLoading}
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={() => {
                      animateScrollTo(0, { maxDuration: 1000 })
                      handleBack();
                    }}
                  >
                    Geri
                  </LoadingButton>

                  <LoadingButton
                    loading={createListingLoading}
                    // type={activeStep === 2 ? "submit" : ""}
                    disabled={
                      activeStep === 3 || (
                        activeStep === 1 && (
                          !values?.exteriorOrganizerName ||
                          !values?.exteriorOrganizerDetails
                          // !values?.facebook ||
                          // !values?.instagram ||
                          // !values?.twitter ||
                          // !values?.website ||
                          // !values?.youtube
                        )
                      ) || (
                        activeStep === 2 && (
                          values.ageInterval === '' ||
                          values.ageInterval === null ||
                          !values.companionship ||
                          !values.format
                        )
                      )
                    }
                    variant="contained"
                    onClick={() => {
                      animateScrollTo(0, { maxDuration: 1000 })

                      if (activeStep === 0) {
                        handleNext();
                      } else if (activeStep === 1) {
                        handleNext();
                      } else if (activeStep === 2) {
                        onSubmit();
                      }
                    }}
                  >
                    {activeStep === 0 ? 'Başla' : activeStep === 1 ? 'İleri' : 'İleri'}
                  </LoadingButton>
                </Box>
              </Stack>
            </FormProvider>
          </Card>
        </Grid>

        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <CreateListingCard post={values} />
          <br />
          <MultiFilePreview files={values?.images} thumbnail />
          {!id && <MultiFilePreview files={values?.images} />}
        </Grid>

        <Dialog
          open={isReview}
          onClose={() => setIsReview(false)}
          sx={{
            '&>*>*': {
              p: 2,
              m: 2,
              width: '100%',
            },
          }}
        >
          <CreateListingCard post={values} />
          <br />
          <MultiFilePreview files={values?.images} thumbnail />
          {!id && <MultiFilePreview files={values?.images} />}
        </Dialog>
      </Grid>
    </Container>
  );
};

const FirstPage = () => {
  const { id } = useParams();
  return (
    <Box
      sx={{
        minHeight: '100px',
        py: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <IconButton
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          p: 2,
          mb: 2,
        }}
      >
        <ChecklistIcon
          sx={{
            width: '100px',
            height: '100px',
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </IconButton>
      <Typography
        sx={{
          fontSize: 35,
          fontFamily: 'sen !important',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {id ? 'Listelemeyi Güncelle' : 'Yeni Listeleme Oluştur'}
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          fontFamily: 'sen !important',
          color: '#999',
          textAlign: 'center',
        }}
      >
        Oluşturduğunuz listelemenin ön izlemesini sağ tarafta görüntüleyebilirsiniz.
      </Typography>
    </Box>
  );
};

const FormOne = ({ setValue, list, selectedOrganizer, setSelectedOrganizer }) => {

  const sortedData = list?.filter(item => item.personalInformation?.organization)
    .sort((a, b) => {
      const orgA = a.personalInformation.organization.toLowerCase();
      const orgB = b.personalInformation.organization.toLowerCase();
      return orgA.localeCompare(orgB, 'tr');
    });


  console.log(selectedOrganizer)
  const handleDropOrganizer = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('exteriorOrganizerImageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFileOrganizer = useCallback(() => {
    setValue('exteriorOrganizerImageUrl', null);
  }, [setValue]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" mb={1}>Organizatör Seç</Typography>
        <RHFSelect label="Organizatör Listesi" name={" "} >
          {sortedData?.map((organizer, index) => (
            <MenuItem key={index} value={organizer?._id}
              onClick={() => setSelectedOrganizer(organizer)}
              sx={{ textTransform: 'capitalize' }}>
              {organizer?.personalInformation?.organization}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>


      <Grid item xs={12}>
        <RHFTextField name="exteriorOrganizerName" label="Aktivite sahibi/Organizatör adı" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField
          multiline
          rows={5}
          name="exteriorOrganizerDetails"
          label="Aktivite sahibi/Organizatör açıklaması"
        />
      </Grid>


      <Grid item xs={12}>
        {/* <Typography variant="subtitle2">Kapak Resmi</Typography> */}

        <RHFUpload
          text1=""
          text2="Aktivite sahibi/Organizatör logosu"
          noImage
          name="exteriorOrganizerImageUrl"
          maxSize={3145728}
          onDrop={handleDropOrganizer}
          onDelete={handleRemoveFileOrganizer}
        />
      </Grid>



      <Grid item xs={12}>
        <RHFTextField name="website" label="Web Sitesi" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="youtube" label="Youtube" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="twitter" label="Twitter" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="instagram" label="Instagram" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="facebook" label="Facebook" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="phoneNumber" label="Telefon Numarası" />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="phoneNumber2" label="Telefon Numarası 2" />
      </Grid>


    </Grid >
  );
};




const FormTwo = ({ values, setValue }) => {

  const { categories } = useSelector((state) => state.parameter);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('coverImage', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue('coverImage', null);
  }, [setValue]);


  const isFaceToFace = (
    values?.category?.includes("Atölyeler") ||
    values?.category?.includes("Kurslar")
  ) && true

  useEffect(() => {
    if (isFaceToFace) {
      setValue("format", values?.format)
    } else {
      setValue("format", "faceToFace")
    }
  }, [setValue, isFaceToFace, values?.format])


  const handleDropImages = useCallback(
    (acceptedFiles) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFromImages = useCallback(
    (inputFile) => {
      const filtered = values.images && values.images?.filter((file) => file !== inputFile);
      setValue('images', filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveFromImagesAllFiles = useCallback(() => {
    setValue('images', []);
  }, [setValue]);

  const categoriesData = categories?.data?.toReversed() ?? [];
  console.log(categoriesData); // Debugging line

  return (
    <Grid container spacing={3}>

      <Grid item xs={12}>
        <RHFTextField name="title" label="Başlık" />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <RHFSelect name="category" label="Kategori">
          {categories?.data?.map((status, i) => (
            <MenuItem
              key={`${status}-${i}`}
              value={status?._id}
              sx={{ textTransform: 'capitalize' }}
            >
              {status?._id?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid> */}

      <Grid item xs={12}>
        <RHFAutocomplete2
          name="category"
          label="Kategori"
          options={categoriesData.map((option) => option._id)}
        />
      </Grid>

      <Grid item xs={12}>
        <RHFTextField multiline rows={4} name="description" label="Açıklama" />
      </Grid>

      <Grid item xs={12}>
        {/* <Typography variant="subtitle2">Kapak Resmi</Typography> */}

        <RHFUpload
          text1="Kapak Resmi"
          name="coverImage"
          maxSize={3145728}
          onDrop={handleDrop}
          onDelete={handleRemoveFile}
        />
      </Grid>

      <Grid item xs={12}>

        <RHFAutocomplete
          name="ageInterval"
          placeholder="Yaş Aralığı"
          multiple
          disableCloseOnSelect
          options={values?.ageInterval?.length === 0 ? ageIntervalsForListing : (values?.ageInterval?.[0]?.name === "Yaş sınırı yok" ? ageIntervalsForListing?.slice(0, 1) : ageIntervalsForListing?.slice(1))}
          getOptionLabel={(option) => option?.value?.toString()}
          isOptionEqualToValue={(option, value) => option?.value === value?.value}
          renderOption={(props, option) => (
            <li {...props} key={option?.value?.toString()}>
              {option?.name}
            </li>
          )}
          renderTags={(selected, getTagProps) => {
            return selected?.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option?.value?.toString()}
                label={option?.name}
                size="small"
                color="info"
                variant="soft"
              />
            ));
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <RHFSelect name="companionship" label="Veli katılımı">
          {companionshipsForListing.map((status, i) => (
            <MenuItem
              key={`${status}-${i}`}
              value={status?.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {status?.name?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>

      <Grid item xs={12}>
        <RHFSelect name="format" label="Format" 
        disabled={!isFaceToFace}>
          {formatsForListing.map((status, i) => (
            <MenuItem
              key={`${status}-${i}`}
              value={status?.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {status?.name?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>

      <Grid item xs={12}>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">Resimler</Typography>
          <RHFUpload
            multiple
            thumbnail
            name="images"
            maxSize={3145728}
            onDrop={handleDropImages}
            onRemove={handleRemoveFromImages}
            onRemoveAll={handleRemoveFromImagesAllFiles}
            onUpload={() => console.info('ON UPLOAD')}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const LastPage = () => {
  return (
    <Box
      sx={{
        minHeight: '100px',
        py: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
      <IconButton
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          p: 2,
          mb: 5,
        }}
      >
        <ThumbUpRoundedIcon
          sx={{
            width: '100px',
            height: '100px',
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </IconButton>
      <Typography
        sx={{
          fontSize: 35,
          fontFamily: 'sen !important',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        Listeleme başarılı bir şekilde oluşturulmuştur.
      </Typography>
      <Button variant="contained" color="primary" sx={{ mt: 5 }} size='large'
        onClick={() => {
          window.location.href = 'https://test.hophop.com.tr/#/listelemeler/olustur';
        }
        }
      >
        Yeni Listeleme Oluştur
      </Button>
    </Box>
  );
};

export default CreateListing;
