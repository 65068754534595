const activityDetail = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_activity($activityId: String!) {
      organizer_activity(activityId: $activityId) {
        _id
        # participantCount
        title
        listing {
          _id
          title
          category
          subCategory
          ageInterval
          format
          description
          images
          coverImage
          companionship
          status
        }
        schedule {
          _id
          repeatedIntervalType
          startDate
          endDate
          totalDayCount
          days {
            date
            day
            startTime
            endTime
          }
          createdAt
          updatedAt
        }
        location {
          _id
          city
          district
          country
          status
          address {
            title
            description
          }
          lat
          long
          parking
        }
        additionalDetails
        quota
        hoursBeforeReservationExpires
        isReservationRequired
        fee
        price
        duration
        status
        #createdBy {
        #  _id
        #  displayName
        #  displayImageUrl
        #  description
        #  role
        #}
        updatedBy {
          _id
          displayName
          displayImageUrl
          description
          role
        }
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}

const allActivities = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Organizer_activities($filter: FilterActivityByOrganizerInput, $pagination: Pagination) {
      organizer_activities(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          participantCount
          title
          additionalDetails
          createdAt
          # createdBy {
          #   _id
          #   displayName
          #   displayImageUrl
          #   description
          #   role
          # }
          duration
          fee
          hoursBeforeReservationExpires
          isReservationRequired
          listing {
            _id
            title
            status
            rejectionDetail
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship

            rating {
              numberOfReviews
              average
            }
            
          }
          location {
            _id
            city
            status
            rejectionDetail
            district
            country
            address {
              title
              description
            }
            lat
            long
            # parking
          }
          price
          quota
          schedule {
            _id
            repeatedIntervalType
            startDate
            endDate
            totalDayCount
            createdAt
            updatedAt
          }
          status
          updatedAt
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
        }
      }
    }
              `,
    variables: {
      "filter": {
        "additionalDetails": null,
        // "createdByCurrentUser": null,
        "duration": {
          "from": null,
          "to": null
        },
        "fee": null,
        "listing": {
          "title": null,
          "format": null,
          "description": null,
          "companionship": null,
          "category": null,
          "ageInterval": null
        },
        "location": {
          "parking": null,
          "district": null,
          "city": null,
          // "byCoordinates": {
          //   "lat": null,
          //   "long": null,
          //   "radius": null
          // }
        },
        "price": {
          "from": null,
          "to": null
        },
        "quota": {
          "from": null,
          "to": null
        },
        // ...(variables?.filter?.schedule?.dateInterval?.from && ({
        "schedule": {
          "finished": variables?.filter?.schedule?.finished ?? null,
          "dateInterval": {
            "from": variables?.filter?.schedule?.dateInterval?.from ?? null,
            "to": variables?.filter?.schedule?.dateInterval?.to ?? null
          },
          "days": null
        }
        // }))
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const parametersForActivity = () => {

  const data = JSON.stringify({
    query: `
    query Organizer_listings($filter: FilterListingByOrganizerInput, $organizerLocationsFilter2: FilterLocationByOrganizerInput) {
      organizer_listings(filter: $filter) {
        count
        data {
          _id
          title
          status
        }
      }
      organizer_locations(filter: $organizerLocationsFilter2) {
        count
        data {
          _id
          address {
            title
          }
          status
        }
      }
    }
    `,
    variables: {
      "filter": {
        "createdByCurrentUser": true
      },
      "organizerLocationsFilter2": {
        "createdByCurrentUser": true
      }
    }


  })

  return data
}

export const activitiesForOrganizer = {
  activityDetail,
  allActivities,
  parametersForActivity,
}