const listingDetail = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_listing($listingId: String!) {
      moderator_listing(listingId: $listingId) {
        _id

        rating {
          average
          createdAt
          numberOfReviews
        }

        ageInterval
        category
        companionship
        coverImage
        createdAt

        createdBy {
          _id
          description
          displayImageUrl
          displayName
          role
        }

        description

        exteriorOrganizer {
          _id
          name
          imageUrl
          details
          socialMedia {
            youtube
            website
            twitter
            instagram
            facebook
          }
            contact {
            phoneNumber
            phoneNumber2
            }
          
          createdAt
          updatedAt
        }

        format
        images

        rating {
          average
          numberOfReviews
          createdAt
          updatedAt
        }

        rejectionDetail
        
        reviews {
          count
          data {
            _id
            ratedModel {
              _id
            }
            ratedRefModel
            createdBy {
              _id
              displayImageUrl
              displayName
              # role
            }
            title
            comment
            purposeType
            rating
            createdAt
            updatedAt
          }
        }
        status
         statusUpdatedBy {
           _id
           displayName
           displayImageUrl
           description
           role
         }
        subCategory
        title
        type
        updatedAt
         updatedBy {
           _id
           displayName
           displayImageUrl
           description
           role
         }
      }
    }
              `,
    variables: {
      "listingId": listingId
    }
  })

  return data

}

const allListings = ({ variables = null }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_listingsOfModerators($filter: FilterListingsOfModeratorsByModeratorInput, $pagination: Pagination) {
      moderator_listingsOfModerators(filter: $filter, pagination: $pagination) {
        count
        data {
          _id

          rating {
            average
            createdAt
            numberOfReviews
          }
          
          ageInterval
          category
          companionship
          coverImage
          createdAt
          createdBy {
            role
            displayName
            displayImageUrl
            description
            _id
          }
          description
          exteriorOrganizer {
            _id
            name
            imageUrl
            details
            socialMedia {
              youtube
              website
              twitter
              instagram
              facebook
            }
            contact {
            phoneNumber
            phoneNumber2
            }

            createdAt
            updatedAt
          }
          format
          images
          rejectionDetail
          status
          statusUpdatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
          subCategory
          title
          type
          updatedAt
          updatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
        }
      }
    }
      `,
    variables: {
      "filter": {
        "ageInterval": null,
        "category": null,
        "companionship": null,
        "createdByCurrentUser": null,
        "description": null,
        "format": null,
        "status": null,
        "title": null,
        "type": "activity"
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })

  return data

}

const allOrganizerListingsForModerator = ({ variables }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_listingsOfOrganizers($filter: FilterListingsOfOrganizersByModeratorInput, $pagination: Pagination) {
      moderator_listingsOfOrganizers(filter: $filter, pagination: $pagination) {
        count
        data {
          _id
          # ageInterval
          category
          companionship
          coverImage
          createdAt
          createdBy {
            role
            displayName
            displayImageUrl
            description
            _id
          }
          description
          exteriorOrganizer {
            _id
            name
            imageUrl
            details
            socialMedia {
              youtube
              website
              twitter
              instagram
              facebook
            }
              contact {
              phoneNumber
              phoneNumber2
              }
            createdAt
            updatedAt
          }
          format
          images
          rejectionDetail
          status
          statusUpdatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
          subCategory
          title
          type
          updatedAt
          updatedBy {
            _id
            description
            displayImageUrl
            displayName
            role
          }
        }
      }
    }
    `,
    variables: {
      "filter": {
        "ageInterval": null,
        "category": null,
        "companionship": null,
        "createdByCurrentUser": null,
        "description": null,
        "format": null,
        "status": "pendingApproval",
        "title": null,
        "type": null
      },
      "pagination": {
        "page": variables?.pagination?.page,
        "pageSize": variables?.pagination?.pageSize
      }
    }
  })

  return data

}


const isUpdatable = ({ listingId }) => {

  const data = JSON.stringify({
    query: `
    query Query($listingId: String!) {
      user_isListingUpdatable(listingId: $listingId)
    }
    `,
    variables: {
      "listingId": listingId
    }
  })

  return data
}

export const listingsForModerator = {
  listingDetail,
  allListings,
  isUpdatable,
  allOrganizerListingsForModerator
}