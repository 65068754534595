const activityDetail = ({ activityId }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_activity($activityId: String!) {
      moderator_activity(activityId: $activityId) {
        _id
        participantCount
        title
        listing {
          _id
          title
          category
          subCategory
          ageInterval
          format
          description
          images
          coverImage
          companionship
        }
        schedule {
          _id
          repeatedIntervalType
          startDate
          endDate
          totalDayCount
          days {
            # _id
            date
            day
            startTime
            endTime
            # createdAt
            # updatedAt
          }
          createdAt
          updatedAt
        }
        location {
          _id
          city
          country
          address {
            title
            description
          }
          lat
          long
          # parking
        }
        additionalDetails
        quota
        hoursBeforeReservationExpires
        isReservationRequired
        fee
        status
        price
        duration
        #createdBy {
        #  _id
        #  displayName
        #  displayImageUrl
        #  role
        #}
        updatedBy {
          _id
          displayName
          displayImageUrl
          role
        }
        createdAt
        updatedAt
      }
    }
              `,
    variables: {
      "activityId": activityId
    }
  })

  return data

}

const allActivities = ({ variables = null }) => {

  const data = JSON.stringify({
    query: `
    query Moderator_activities($pagination: Pagination, $filter: FilterActivityByModeratorInput) {
      moderator_activities(pagination: $pagination, filter: $filter) {
        count
        data {
          _id
          title
          listing {
            _id
            title
            category
            subCategory
            ageInterval
            format
            description
            images
            coverImage
            companionship
            rating {
              average
              numberOfReviews
              createdAt
              updatedAt
            }
            status
            rejectionDetail
            exteriorOrganizer {
              _id
              name
              imageUrl
              details
              createdAt
              updatedAt
              contact {
              phoneNumber
              phoneNumber2
              }
            }
          }
          schedule {
            _id
            repeatedIntervalType
            startDate
            endDate
            totalDayCount
            days {
              day
              date
              endTime
              startTime
              createdAt
            }
            createdAt
            updatedAt
          }
          location {
            _id
            city
            district
            country
            address {
              title
              description
            }
            lat
            long
            # parking
            status
            rejectionDetail
          }
          additionalDetails
          quota
          hoursBeforeReservationExpires
          isReservationRequired
          fee
          price
          duration
          status
          participantCount
          createdBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          updatedBy {
            _id
            displayName
            displayImageUrl
            description
            role
          }
          createdAt
          updatedAt
        }
      }
    }
    `
    ,
    variables: {
      "filter": {
        "additionalDetails": null,
        "createdByCurrentUser": variables?.filter?.createdByCurrentUser,
        "duration": {
          "from": null,
          "to": null
        },
        "fee": null,
        "listing": {
          "title": null,
          "format": null,
          "description": null,
          "companionship": null,
          "category": null,
          "ageInterval": null
        },
        "location": {
          "parking": null,
          "district": null,
          "city": null,
          // "byCoordinates": {
          //   "lat": null,
          //   "long": null,
          //   "radius": null
          // }
        },
        "price": {
          "from": null,
          "to": null
        },
        "quota": {
          "from": null,
          "to": null
        },
        // ...(variables?.filter?.schedule?.dateInterval?.from && ({
        "schedule": {
          "dateInterval": {
            "from": variables?.filter?.schedule?.dateInterval?.from ?? null,
            "to": variables?.filter?.schedule?.dateInterval?.to ?? null
          },
          "days": null
        }
        // }))
      },
      "pagination": {
        "page": variables?.pagination?.page ?? null,
        "pageSize": variables?.pagination?.pageSize ?? null
      }
    }
  })


  return data

}

const parametersForActivity = () => {

  const data = JSON.stringify({
    query: `
    query parametersForActivity($filter: FilterListingsOfModeratorsByModeratorInput) {
      moderator_listingsOfModerators(filter: $filter) {
        data {
          _id
          title
        }
      }
      moderator_locationsOfModerators {
        data {
          _id
          address{
            title
          }
        }
      }
    }
    `,
    variables: {
      "filter": {
        "type": "activity"
      }
    }
  })

  return data
}


const settings = () => {

  const data = JSON.stringify({
    query: `query Settings {
      settings {
        dashboardComponents {
          _id
          name
          nameVisible
          componentType
          categories {
            _id
            imageUrl
            description
            defaultSorting
          }
          createdAt
          updatedAt
        }
      }
    }`
  })

  return data
}



export const activitiesForModerator = {
  activityDetail,
  allActivities,
  parametersForActivity,
  settings
}